
#menuToggle {
    display: none;
    position: fixed;
    top: 18px;
    left: 88vw;
    z-index: 999999;
    background: white;
    border-radius: 5px;
    padding: 0 5px;
}

@media screen and (max-width: 1000px) and (min-width: 801px) {

    
    #sidebarContainerLL {
        width: 70px;
        flex: none;
    }

    #sidebarLL img {
        width: 100%;
    }
   
    #sidebarLL div a div, #sidebarLL div button div {
        display: none;
    }

    #sidebarLL .d-sm-none {
        display: none;
    }
}

@media screen and (max-width: 800px) {

    #menuToggle {
        display: block;
    }

    #sidebarContainerLL {
        position: fixed;
        left: -500px;
        bottom: 0px;
        top: 0px;
        z-index: 99999;
        width: 400px;
        max-width: 75vw;
        height: 100%;
        transition: left 1s;
    }

    #sidebarContainerLL.show {
        left: 0px;
    }
   
}