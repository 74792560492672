body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: rgb(61, 45, 83);
}


#warning-button {
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animated {
  border-radius: 3px;
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  from {
    background-color: #3972d6;
  }
}

.css-2z08i3.css-2z08i3 {
  color: #42526E !important;
}
.css-nv1fxi-NavLine[data-selected] {
  background-color: #EE7768 !important;
}
.css-d7j155-ButtonBase, .css-d7j155-ButtonBase:hover, .css-d7j155-ButtonBase:active, .css-d7j155-ButtonBase:focus, .css-d7j155-ButtonBase:visited, .css-d7j155-ButtonBase:disabled, .css-d7j155-ButtonBase[disabled] {
  background: #EE7768 !important;
}

.css-1qw8tpg-ButtonBase, .css-1qw8tpg-ButtonBase:hover, .css-1qw8tpg-ButtonBase:active, .css-1qw8tpg-ButtonBase:focus, .css-1qw8tpg-ButtonBase:visited, .css-1qw8tpg-ButtonBase:disabled, .css-1qw8tpg-ButtonBase[disabled] {
  background: #EE7768 !important;
}
