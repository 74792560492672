.green {
  stroke: #55A470	;
  fill: #55A470	;
}
.yellow {
  stroke: #E5DA65	;
  fill: #E5DA65	;
}
.red {
  stroke: #8772A5	;
  fill: #8772A5	;
}
.orange {
  stroke: #3D2D53	;
  fill: #3D2D53	;
}
.black {
  stroke: #FF6F61	;
  fill: #FF6F61	;
}
.ct-label{
  fill: #dfe1e6;
  color: #dfe1e6;
  font-size: .9rem;
}

/* .ct-square{
  width: 400px
} */