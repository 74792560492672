@media screen and (max-width: 1000px) and (min-width: 801px) {

    
    #headerLL {
        width: calc(100vw - 102px);
    }

    #headerLL > div {
        width: calc(100vw - 178px) !important;
        left: 102px !important;
    }

    #headerLL > #headerNavBarLL {
        width: calc(100vw - 102px) !important;
    }

}

@media screen and (max-width: 800px) {

    #headerLL {
        width: 100vw;
    }

    #headerLL > #headerNavBarLL {
        width: 100vw !important;
        left: 0px !important;
    }

    #headerLL > #headerNavBarLL > div {
        padding-right: 12vw;

    }

    #headerLL > #headerNavBarLL > div > img {
        display: none;
    }
   
}